// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_Footer__WiYT0 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2.5rem;
  padding: 3.5rem 0 2rem 0;
  border-top: 1px solid rgba(234, 236, 240, 1);
  gap: 1rem;
}


.Footer_Content__-mqKN {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.Footer_Link__abupq {
  text-decoration: none;
  position: relative;
}

.Footer_Dot__SkfyL {
  position: absolute;
  left: 5.1rem;
  top: 50%;
}

.Footer_Text__U0jgw {
  font-size: 1.6rem;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
}

@media (min-width: 768px) {
  .Footer_Footer__WiYT0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Footer_Content__-mqKN {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Footer_Image__t7DFZ {
    display: inline-block;
    width: 15rem;
  }

  .Footer_Dot__SkfyL {
    position: absolute;
    left: 4rem;
    top: 44%;
  }

  .Footer_Text__U0jgw {
    font-size: 1.25rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,wBAAwB;EACxB,4CAA4C;EAC5C,SAAS;AACX;;;AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,qBAAqB;IACrB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,QAAQ;EACV;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".Footer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin-top: 2.5rem;\n  padding: 3.5rem 0 2rem 0;\n  border-top: 1px solid rgba(234, 236, 240, 1);\n  gap: 1rem;\n}\n\n\n.Content {\n  display: flex;\n  flex-direction: column;\n  gap: 1.6rem;\n}\n\n.Link {\n  text-decoration: none;\n  position: relative;\n}\n\n.Dot {\n  position: absolute;\n  left: 5.1rem;\n  top: 50%;\n}\n\n.Text {\n  font-size: 1.6rem;\n  font-weight: 400;\n  color: rgba(102, 112, 133, 1);\n}\n\n@media (min-width: 768px) {\n  .Footer {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  .Content {\n    width: 100%;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  .Image {\n    display: inline-block;\n    width: 15rem;\n  }\n\n  .Dot {\n    position: absolute;\n    left: 4rem;\n    top: 44%;\n  }\n\n  .Text {\n    font-size: 1.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": `Footer_Footer__WiYT0`,
	"Content": `Footer_Content__-mqKN`,
	"Link": `Footer_Link__abupq`,
	"Dot": `Footer_Dot__SkfyL`,
	"Text": `Footer_Text__U0jgw`,
	"Image": `Footer_Image__t7DFZ`
};
export default ___CSS_LOADER_EXPORT___;
