// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialSection_SocialUl__JDMB9 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  list-style: none;
  padding: 2.4rem 0;
  margin-top: 2rem;
}

.SocialSection_List__d0Yca {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SocialSection_Link__pqROg {
  text-decoration: none;
}

.SocialSection_Image__teFl5 {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SocialSection/SocialSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,cAAc;AAChB","sourcesContent":[".SocialUl {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 2rem;\n  list-style: none;\n  padding: 2.4rem 0;\n  margin-top: 2rem;\n}\n\n.List {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.Link {\n  text-decoration: none;\n}\n\n.Image {\n  display: inline-block;\n  width: 2.4rem;\n  height: 2.4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SocialUl": `SocialSection_SocialUl__JDMB9`,
	"List": `SocialSection_List__d0Yca`,
	"Link": `SocialSection_Link__pqROg`,
	"Image": `SocialSection_Image__teFl5`
};
export default ___CSS_LOADER_EXPORT___;
