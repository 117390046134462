// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileSection_ProfileContainer__MHscj {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  margin-top: 1rem;
}

.ProfileSection_Picture__SkT5y {
  display: inline-block;
  width: 8.8rem;
  height: 8.8rem;
  border-radius: 50%;
}

.ProfileSection_ProfileLink__VgSvF {
  text-decoration: none;
  color: #101828;
  font-size: 1.8rem;
  font-weight: 700;
}

.ProfileSection_ShouldNotVisible__1\\+QSB {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileSection/ProfileSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".ProfileContainer {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1.6rem;\n  margin-top: 1rem;\n}\n\n.Picture {\n  display: inline-block;\n  width: 8.8rem;\n  height: 8.8rem;\n  border-radius: 50%;\n}\n\n.ProfileLink {\n  text-decoration: none;\n  color: #101828;\n  font-size: 1.8rem;\n  font-weight: 700;\n}\n\n.ShouldNotVisible {\n  visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProfileContainer": `ProfileSection_ProfileContainer__MHscj`,
	"Picture": `ProfileSection_Picture__SkT5y`,
	"ProfileLink": `ProfileSection_ProfileLink__VgSvF`,
	"ShouldNotVisible": `ProfileSection_ShouldNotVisible__1+QSB`
};
export default ___CSS_LOADER_EXPORT___;
