// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkSection_LinkSection__058y\\+ {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  list-style: none;
}

.LinkSection_List__QqGU5 {
  width: 100%;
}

.LinkSection_Link__wGLh3 {
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 2.4rem 3.2rem;
  font-size: 1.4rem;
  border-radius: 8px;
  font-weight: 500;
  background: #EAECF0;
  color: #101828;
}`, "",{"version":3,"sources":["webpack://./src/components/LinkSection/LinkSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".LinkSection {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1.6rem;\n  list-style: none;\n}\n\n.List {\n  width: 100%;\n}\n\n.Link {\n  display: block;\n  text-align: center;\n  text-decoration: none;\n  padding: 2.4rem 3.2rem;\n  font-size: 1.4rem;\n  border-radius: 8px;\n  font-weight: 500;\n  background: #EAECF0;\n  color: #101828;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LinkSection": `LinkSection_LinkSection__058y+`,
	"List": `LinkSection_List__QqGU5`,
	"Link": `LinkSection_Link__wGLh3`
};
export default ___CSS_LOADER_EXPORT___;
